'use client';

import * as React from 'react';
import { styled } from '@linaria/react';

import { BREAKPOINTS } from '@/constants';
import usePrefersReducedMotion from '@/hooks/use-prefers-reduced-motion';
import useBoundingBox from '@/hooks/use-bounding-box';
import useFontRatio from '@/hooks/use-font-ratio';

import ClientOnly from '@/components/ClientOnly';
import InPortal from '@/components/InPortal';
import JericaMascot, { Mood } from '@/components/JericaMascot/JericaMascot';

import ArtProvider from './ArtProvider';
import ConnectedGenerativeArt from './ConnectedGenerativeArt';
import EditButton, { MIN_DISTANCE_TO_TOP } from './EditButton';
import EditPanel from './EditPanel';

function HomepageConfigurableArt() {
  const [showEditButton, setShowEditButton] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);
  const [disableMouseTracking, setDisableMouseTracking] =
    React.useState(false);
  const hasBeenShown = React.useRef(false);

  const [joshMood, setJericaMood] = React.useState<Mood>('happy');

  const editButtonTimeoutId = React.useRef<number | null>(null);
  const joshMoodTimeoutId = React.useRef<number | null>(null);

  const editButtonRef = React.useRef<HTMLButtonElement>(null);
  const showEditButtonRef = React.useRef(showEditButton);
  showEditButtonRef.current = showEditButton;

  const box = useBoundingBox(editButtonRef);

  const prefersReducedMotion = usePrefersReducedMotion();

  const fontRatio = useFontRatio();

  React.useEffect(() => {
    return () => {
      if (editButtonTimeoutId.current) {
        window.clearTimeout(editButtonTimeoutId.current);
      }
      if (joshMoodTimeoutId.current) {
        window.clearTimeout(joshMoodTimeoutId.current);
      }
    };
  }, []);

  // The panel doesn't work great when boosting font sizes a ton
  const enableInteractiveFeatures =
    !prefersReducedMotion && fontRatio < 1.5;

  // In addition to the “show after interacting” logic, I also want to show/hide the button based on how close it gets to the top of the viewport. This is because I have a bunch of icons in the header, and it looks janky if they overlap. So, as the user scrolls, the edit button will automatically fade out when it gets close to the top.
  // For keyboard users, I've added some `scroll-margin-top` to the button itself (in /EditButton) which should mean there isn't a conflict here.
  const isEditButtonTooCloseToTop =
    typeof box?.top === 'number' && box.top < MIN_DISTANCE_TO_TOP;

  return (
    <ArtProvider>
      <ArtPositioner>
        <ArtWrapper
          onMouseEnter={() => {
            // We want to show the edit button after the user has been engaging with the art for a brief period of time.
            if (!enableInteractiveFeatures || showEditButton) {
              return;
            }

            // On *subsequent* mouse enters, show the edit button immediately. They don't need to wait the two seconds every time.
            if (hasBeenShown.current) {
              setShowEditButton(true);
              return;
            }

            editButtonTimeoutId.current = window.setTimeout(() => {
              setShowEditButton(true);
              hasBeenShown.current = true;
            }, 2000);
          }}
          onMouseLeave={() => {
            if (editButtonTimeoutId.current) {
              window.clearTimeout(editButtonTimeoutId.current);
            }

            if (!isEditing) {
              editButtonTimeoutId.current = window.setTimeout(() => {
                setShowEditButton(false);
              }, 1000);
            }
          }}
        >
          <ConnectedGenerativeArt
            width={1000}
            height={520}
            enableInteractiveFeatures={enableInteractiveFeatures}
            disableMouseTracking={disableMouseTracking}
            isEditing={isEditing}
          />
        </ArtWrapper>
      </ArtPositioner>

      {enableInteractiveFeatures && (
        <EditButtonPositioner>
          <EditButtonWrapper
            data-is-visible={
              showEditButton && !isEditButtonTooCloseToTop
            }
          >
            <EditButton
              ref={editButtonRef}
              isEditing={isEditing}
              onMouseEnter={() => {
                if (editButtonTimeoutId.current) {
                  window.clearTimeout(editButtonTimeoutId.current);
                }
              }}
              onClick={() => {
                setIsEditing((prev) => !prev);
              }}
              style={{
                pointerEvents: isEditButtonTooCloseToTop
                  ? 'none'
                  : 'auto',
              }}
            />
          </EditButtonWrapper>
        </EditButtonPositioner>
      )}

      {isEditing && (
        <InPortal>
          <EditPanelPositioner>
            <EditPanel
              handleDismiss={() => {
                setIsEditing(false);
                setDisableMouseTracking(false);
              }}
              onRandomize={() => {
                setJericaMood('very-happy');

                if (joshMoodTimeoutId.current) {
                  window.clearTimeout(joshMoodTimeoutId.current);
                }

                joshMoodTimeoutId.current = window.setTimeout(() => {
                  setJericaMood('happy');
                }, 800);
              }}
              // It's distracting if the generative art is responding to mouse movements while the user is editing the configuration. Disable mouse tracking while the mouse is over the edit panel.
              onMouseEnter={() => {
                setDisableMouseTracking(true);
              }}
              onMouseLeave={() => {
                setDisableMouseTracking(false);
              }}
            />
          </EditPanelPositioner>
        </InPortal>
      )}

      <MascotWrapper>
        <JericaMascot mood={joshMood} />
      </MascotWrapper>
    </ArtProvider>
  );
}

// Getting the rainbow correctly positioned across all viewport sizes has been tricky! We're creating a center-aligned flex column, which puts the rainbow right in the middle. Then we translate it, inside `ArtWrapper`, to be aligned with JericaMascot on non-mobile screens.
const ArtPositioner = styled.div`
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
  /*
    HACK: So, with default font size, there's exactly 16px of extra space below the art. I want it to be top-aligned in this case, but bottom-aligned when the user cranks their default font size. Since there is no font-size media query, I'm adding 16px of padding, which works well across both situations.
    This is a BAD FIX and I should really just spend 10 minutes and find a better implementation.
  */
  padding-bottom: 16px;
  transition: opacity var(--color-swap-duration);

  html[data-color-mode='light'] & {
    opacity: 0.8;
  }
`;

/* NOTE: `ClientOnly` is required to ensure that the canvas gets initialized correctly, with the correct device pixel ratio. */
const ArtWrapper = styled(ClientOnly)`
  transform: translateX(20.625rem);
  transition: opacity 200ms;

  /* Hide the rainbow below a certain screen size, since we'd only see a sliver of it. */
  @media ${BREAKPOINTS.mdAndSmaller} {
    opacity: 0;
  }
`;

const EditButtonPositioner = styled.div`
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  margin: auto;
  width: 50rem;
  bottom: 60px;
  display: flex;
  justify-content: flex-end;
  pointer-events: none;

  @media (max-width: 58rem) {
    left: revert;
    right: 64px;
    width: 100%;
  }

  @media (max-width: 43.75rem) {
    display: none;
  }
`;

const EditButtonWrapper = styled.div`
  opacity: 0;
  transform: scale(0.5) translate(-32px, 64px);
  pointer-events: none;
  /* On enter, the button has a translate animation. On exit, though, I don't want it to move back to that original position. My hacky solution is to use a REALLY long transition duration here. That way, it won't actually move before it's faded out.
  While this seems janky, it's the best solution I've found for gracefully handling interrupts, quickly toggling between shown and hidden. */
  transition:
    opacity 800ms,
    transform 200000ms;

  &[data-is-visible='true'],
  &:focus-within {
    opacity: 1;
    transform: scale(1) translate(0px, 0px);
    pointer-events: auto;
    transition: all 500ms cubic-bezier(0.17, 0.67, 0.53, 0.97);
  }
`;

const EditPanelPositioner = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 8rem;
  margin: auto;
  width: 68.75rem;
  max-width: 100%;
  display: flex;
  justify-content: flex-start;
  pointer-events: none;
`;

const MascotWrapper = styled.div`
  position: absolute;
  z-index: 4;
  left: 0;
  right: 0;
  margin: auto;
  width: 50rem;
  bottom: 60px;
  display: flex;
  justify-content: flex-end;
  pointer-events: none;

  @media (max-width: 58rem) {
    margin: revert;
    left: revert;
    right: 64px;
  }
  @media (max-width: 50rem) {
    display: none;
  }
`;

export default React.memo(HomepageConfigurableArt);
